import { Routes, Route } from 'react-router-dom';
import Layout from '@/components/common/Layout';
import IndexPage from '@/pages/index';
import EntryPage from '@/pages/[entry]';
import NotFoundPage from '@/pages/not_found';
import { isEnableAuth } from '@/util/appConfig';
import ReleaseNotesPage from '@/pages/release-notes';
import SignInCallback from '@/components/tier4account/SignInCallback';
import SignOutCallback from '@/components/tier4account/SignOutCallback';
import CookiePolicyPage from '../../../pages/cookie-policy';
import { useAppRouter } from './hooks';

const AppRoutes: React.FC = () => {
  const { isLoading } = useAppRouter();

  if (isEnableAuth && isLoading) {
    return (
      <Routes>
        <Route path="/login/callback/" element={<SignInCallback />} />
        <Route path="/logout/callback/" element={<SignOutCallback />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {isEnableAuth && (
        <>
          <Route path="/login/callback/" element={<SignInCallback />} />
          <Route path="/logout/callback/" element={<SignOutCallback />} />
        </>
      )}
      <Route path="" element={<Layout />}>
        <Route index element={<IndexPage />} />
        <Route path="release-notes/*" element={<ReleaseNotesPage />}>
          <Route path=":year" element={<ReleaseNotesPage />} />
        </Route>
        <Route path="cookie-policy" element={<CookiePolicyPage />} />
        <Route path=":page_id/*" element={<EntryPage />} />
      </Route>
      <Route path="en" element={<Layout />}>
        <Route index element={<IndexPage />} />
        <Route path="release-notes/*" element={<ReleaseNotesPage />}>
          <Route path=":year" element={<ReleaseNotesPage />} />
        </Route>
        <Route path="cookie-policy" element={<CookiePolicyPage />} />
        <Route path=":page_id/*" element={<EntryPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
