import DocumentWrapper from '../DocumentWrapper';
import { useCookieBotDeclaration } from './hooks';

const CookieBotDeclaration: React.FC = () => {
  const { ref } = useCookieBotDeclaration();

  return (
    <DocumentWrapper id="cookie-policy">
      <h1>Cookie Policy</h1>
      <div ref={ref} />
    </DocumentWrapper>
  );
};

export default CookieBotDeclaration;
