import CookieBotDeclaration from '@/components/common/CookieBotDeclaration';
import Helmet from '@/components/common/Helmet';
import LowerWrapper from '@/components/common/LowerWrapper';

const CookiePolicyPage: React.FC = () => {
  return (
    <LowerWrapper>
      <Helmet title="Cookie Policy" />
      <CookieBotDeclaration />
    </LowerWrapper>
  );
};

export default CookiePolicyPage;
