import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const useCookieBotDeclaration = () => {
  const { i18n } = useTranslation();
  const first = useRef(true);
  const ref = useRef<HTMLDivElement>(null);

  const clear = useCallback(() => {
    const containerElement = ref.current;
    if (containerElement) {
      containerElement.innerHTML = '';
    }
  }, []);

  // <script id="CookieDeclaration" src="https://consent.cookiebot.com/647f1a56-2969-4e38-9dfb-a3e7195ebcbe/cd.js" type="text/javascript" async></script>
  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && first.current) {
      first.current = false;
      return;
    }
    clear();
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.id = 'CookieDeclaration';
    scriptElement.dataset['culture'] = i18n.language;
    scriptElement.async = true;
    scriptElement.src = `https://consent.cookiebot.com/${import.meta.env.VITE_CB_ID}/cd.js`;
    const containerElement = ref.current;
    if (!containerElement) return;
    containerElement.appendChild(scriptElement);

    return () => {
      clear();
    };
  }, [i18n.language, clear]);

  return {
    ref,
  };
};
