import GlobalStyles from '@/styles/GlobalStyles';
import AppRoutes from '@/components/common/AppRoutes';
import { useEffect, useRef } from 'react';
import { isEnableAuth } from '@/util/appConfig';

const App = () => {
  const first = useRef(true);

  useEffect(() => {
    if (!isEnableAuth) return;
    if (process.env.NODE_ENV === 'development' && first.current) {
      first.current = false;
      return;
    }
  }, []);

  return (
    <>
      <GlobalStyles />
      <AppRoutes />
    </>
  );
};

export default App;
